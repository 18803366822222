import React, {Component} from 'react';
import formStyles from "./Form.module.css";

export default class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
        }
    }

    getReadOnly() {
        if (this.props.isReadOnly)
            return this.props.isReadOnly;
        return false;
    }

    componentDidMount() {
        if (this.props.data) {
            this.setState({
                data: {...this.props.data}
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.data !== prevProps.data) {
            this.setState({
                data: {...this.props.data}
            });
        }

    }

    onSubmit(event) {
        event.preventDefault();
        this.props.onSubmit(this.state.data);
    }

    render() {
        const childrenWithProps = React.Children.map(this.props.children, child => {
            if (!React.isValidElement(child))
                return;

            let isReadOnly = this.getReadOnly();
            let name = null;
            let onChangeCustom = null;

            if (child.props) {
                isReadOnly = isReadOnly || child.props.isReadOnly;
                name = child.props.name || null;
                onChangeCustom = child.props.onChange;
            }

            let value = null;
            if (name && this.state.data[name] !== undefined)
                value = this.state.data[name];
            if (name && value === null)
                value = '';

            // noinspection JSUnresolvedFunction
            return React.cloneElement(child, {
                isReadOnly,
                value,
                onChange: value => {
                    if (name)
                        this.setState({
                            data: {...this.state.data, [name]: value}
                        });
                    if (onChangeCustom)
                        onChangeCustom(value);
                }
            });
        });
        return <form className={formStyles.Form} onSubmit={e => this.onSubmit(e)}>
            {childrenWithProps}
        </form>
    }
}

import Tools from './Tools';

export default class URLState {
	/**
	 * Configuration for the URL to STATE (and reverse) mapping
	 *
	 * urlKey = 	The string used in the URL
	 * name = 		The key used in the state object
	 * empty = 		The value to set in the state object if the urlKey isn't present in the URL
	 * value = 		A value to set in the state object if the urlKey IS present. If "value" is not set,
	 * 				the next part of the URL will be used as value
	 *
	 * @type {*[]}
	 */
	static config = [
		{
			urlKey: 'admin',
			name: 'page',
			value: 'admin',
		},
		{
			urlKey: 'play',
			name: 'subscription_id',
			empty: null,
		},
		{
			urlKey: 'observe',
			name: 'observer_url_id',
			empty: null,
		},
		{
			urlKey: 'deck',
			name: 'deck_id',
			empty: null,
		},
		{
			urlKey: 'welcome',
			name: 'page',
			value: 'welcome',
		},
		/*{
			urlKey: 'id',
			name: 'unique_id',
			empty: null,
		},*/
	];

	static getConfigByUrlKey(urlKey) {
		return URLState.config.find( c => c.urlKey === urlKey );
	}

	static fillState(fillObject) {
		let stateObj = {};

		URLState.config.forEach(c => {
			if (c.empty !== undefined)
				stateObj[c.name] = c.empty
		});

		return {
			...stateObj,
			...fillObject,
		};
	}

	/**
	 * Split a URL by "/", get keys and values and apply to state object according to the configuration
	 *
	 * @param url
	 */
	static urlToState(url) {
		let urlKey = false;

		let stateObj = URLState.fillState({});

		url.split('/').forEach(item => {
			if (urlKey !== false) {
				let value = item;
				let c = URLState.getConfigByUrlKey(urlKey);
				if (!c)
					return;

				if (c.type === 'integer array') {
					stateObj[c.name] = value.split(',').map(i => parseInt(i, 10));
				} else {
					stateObj[c.name] = value;
				}
				urlKey = false;
				return;
			}

			urlKey = item;

			let c = URLState.getConfigByUrlKey(urlKey);

			if (!c) {
				urlKey = false;
				return;
			}

			if (c.value) {
				// If a value exists in the config object, the value will not be included in the URL
				stateObj[c.name] = c.value;
				urlKey = false;
			}
		});

		return stateObj;
	}

	/**
	 * Generate a URL from a state object according to the configuration
	 *
	 * @param state
	 * @returns {string}
	 */
	static stateToURL(state) {
		let url = '';
		let handledStateVars = [];

		// Iterate over all configuration entries

		URLState.config.forEach(c => {
			let varName = c.name;
			let urlKey = c.urlKey;

			// Check if the configuration option is set in the state
			if (!state[varName])
				return;

			// Check if we have already handled this option
			if (handledStateVars.indexOf(varName) !== -1)
				return;

			if (c.value) {
				if (state[varName] === c.value) {
					url += '/' + urlKey;
					handledStateVars.push(varName);
				}

				return;
			}

			if (Array.isArray(state[varName])) {
				if (state[varName].length === 0)
					return;

				url += '/' + urlKey + '/' + state[varName].join(',');

				handledStateVars.push(varName);

				return;
			}

			url += '/' + urlKey + '/' + state[varName];
			handledStateVars.push(varName);

		});

		return url;
	}

	static hasChanged(currentState, currentLocation) {
		if (URLState.stateToURL(currentState) === currentLocation)
			return false;

		let locationState = this.urlToState(currentLocation);

		return Object.keys(locationState).find( key => {
			// Returning true here will cause the method "hasChanged" to return true
			// Return false here will go to next key in object

			if (currentState[key] === undefined)
				return true;

			if (Array.isArray(locationState[key]))
				return !Tools.integerArrayEqual(locationState[key], currentState[key]);

			return locationState[key] !== currentState[key];
		}) !== undefined;
	}

	static isAtRoot(currentState) {
		return !URLState.hasChanged(currentState, "/");
	}

	static urlIsAtRoot(url) {
		return !URLState.hasChanged(this.urlToState(url), "/");
	}

}
import React, { Component } from 'react';
import './App.css';
import URLState from "./client/URLState";
import { createBrowserHistory as createHistory } from 'history';
import PlayBoard from "./client/play/PlayBoard";
import Admin from "./client/admin/Admin";
import Welcome from "./client/page/Welcome";

class App extends Component {
	constructor(props) {
		super(props);

		this.initSubscriptionID = null;

		const myStorage = window.localStorage;
		if (myStorage && myStorage.getItem('subscription_id')) {
			try {
				this.initSubscriptionID = myStorage.getItem('subscription_id');
				console.log(`Subscription ID "${this.initSubscriptionID}" loaded from local storage`);
			} catch (e) {
				console.error('Local storage failed', e);
			}
		}

		this.state = {
			subscription_id: this.initSubscriptionID,
			deck: null,
		};

		this.history = createHistory();
		this.history.listen((location) => this.historyChanged(location));
		App.googleAnalyticsSendPageView();
	}
	componentDidMount() {
		this.historyChanged(this.history.location);
		//this.loadGoogleMapsApi();
	}
	static googleAnalytics(command, ...commandParameters) {
		// noinspection JSUnresolvedFunction
		if (typeof(window.gtag) === 'function') {
			// noinspection JSUnresolvedFunction
			window.gtag(command, ...commandParameters);
		}
	}
	static googleAnalyticsSendPageView() {
		let href = decodeURIComponent(window.location.pathname);
		let state = URLState.urlToState(href);
		let URL = URLState.stateToURL(state);

		App.googleAnalytics('config', process.env.REACT_APP_GOOGLE_ANALYTICS, {
			'page_path': URL,
		});
	}

	historyChanged(location) {
		if (location.action === 'PUSH') {
			App.googleAnalyticsSendPageView();
			return;
		}

		let newState = URLState.urlToState(location.pathname);

		// If no subscription was found in the URL, get the one from our "local storage" and replace the null value
		let replaceSubscription = {	};
		if (newState.subscription_id === null)
			replaceSubscription.subscription_id = this.initSubscriptionID;

		this.setState({
			...newState,
			...replaceSubscription,
		});

		if (newState.page !== 'admin' && newState.subscription_id) {
			// The subscription ID code has been copied from the URL to the state, remove it from the URL
			// A "history push" will not cause us to change the state so this is ok
			this.history.push('/play');

			const myStorage = window.localStorage;
			if (myStorage) {
				try {
					myStorage.setItem('subscription_id', newState.subscription_id);
					console.log('Subscription ID saved to local storage');
				} catch (e) {
					console.error('Local storage failed', e);
				}
			}
		}
	}
	static renderAdmin() {
		return <Admin/>
	}
	renderPlay() {

		return (
			<PlayBoard subscriptionID={this.state.subscription_id} />
		);

	}

	renderObserver() {
		return (
			<PlayBoard observerURLID={this.state.observer_url_id} />
		);
	}

	static renderWelcome() {
		return <Welcome/>
	}
	
	render() {

		if (this.state.page === 'admin')
			return App.renderAdmin();

		if (this.state.observer_url_id !== null)
			return this.renderObserver();

		if (this.state.subscription_id !== null)
			return this.renderPlay();

		if (this.state.page === 'welcome')
			return App.renderWelcome();

		window.location.href = 'https://www.ordrum.se/butik/utbildningsmaterial/talarkortleken-grundversionen/';
		return <div>Redirecting</div>;
	}
}

export default App;

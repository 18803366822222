import React, {Component} from 'react';
import Form from "../form/Form";
import Text from "../form/Text";
import Password from "../form/Password";
import Submit from "../form/Submit";

export default class Login extends Component {
    render() {
        return <Form onSubmit={data => this.props.onLogin(data.username, data.password)}>
            <Text name={'username'} title={'Användarnamn'}/>
            <Password name={'password'} title={'Lösenord'}/>
            <Submit title={'Logga in'}/>
        </Form>
    }
}

import React, {Component} from 'react';
import styles from "./Admin.module.css";

export default class Menu extends Component {
    render() {
        return <div className={styles.Menu}>
            <div className={styles.MenuTitle}/>
            {this.props.items.map(item => {
                return <div className={styles.MenuItem} key={item.id} onClick={event => this.props.onClick(event, item)}>
                    {item.title}
                </div>
            })}
        </div>
    }
}

import React, {Component} from 'react';
import Utils from "../Utils";
import Message from "./Message";
import Form from "../form/Form";
import Select from "../form/Select";
import Date from "../form/Date";
import TextArea from "../form/TextArea";
import Text from "../form/Text";
import Submit from "../form/Submit";
import SubscriptionDetails from "./SubscriptionDetails";
import {getTranslation} from "../language/Language";

export default class CreateSubscription extends Component {
    constructor(props) {
        super(props);


        this.state = {
            isError: false,
            message: null,
            savedSubscriptionID: null,
            lang:'swedish'
        }
    }
    onCreateNew(data) {
        if (!data.deck_id)
            return this.setState({message: 'Du måste välja en kortlek', isError:true});

        if (!data.expire_date /*|| !data.comment*/ || !data.contact_name || !data.contact_info)
            return this.setState({message: 'Var vänlig fyll i alla fält', isError:true});

        Utils.Fetch({
            action:'create_subscription',
            subscription: data
        }).then(res => {
            this.setState({
                newSubscription: {
                    ...this.emptySubcription,
                },
                isError: false,
                message: 'Ny prenumeration tillagd',
                savedSubscriptionID: res.subscription_id,
            });
        }).catch(message => {
            this.setState({
                isError: true,
                message: message.toString(),
            });
        });
    }

    onLangChange(lang) {
        this.setState({lang});
    }

    render() {

        return <div>
            {this.state.message ? <Message isError={this.state.isError}>
                {this.state.message}
                </Message> : null}

            {this.state.savedSubscriptionID ?
                <SubscriptionDetails baseData={this.props.baseData}
                                     ID={this.state.savedSubscriptionID}
                                     onClose={() => {
                                         this.setState({
                                             savedSubscriptionID: null
                                         });
                                         this.load();
                                     }}/>
                :
                <Form onSubmit={data => this.onCreateNew(data)}>
                    <Select onChange={this.onLangChange.bind(this)}
                            name={'lang'}
                            title={'Språk'}
                            notSelectedText={'-- Välj språk --'}
                            options={this.props.baseData.decks.filter((value, index, array) => {
                                return array.findIndex( value2 => value.language === value2.language) === index;
                            }).map(deck => ({value: deck.language, text: getTranslation(deck.language,'swedish')}))}/>
                    <Select name={'deck_id'}
                            title={'Kortlek'}
                            notSelectedText={'-- Välj kortlek --'}
                            options={this.props.baseData.decks.filter(deck => {
                                if (!deck.language)
                                    return true;
                                else if (deck.language === this.state.lang)
                                    return true
                                return false;
                            }).map(deck => ({value: deck.id, text: deck.name}))}/>
                    <Date name={'expire_date'} title={'Giltig till'}/>
                    <TextArea name={'comment'} title={'Kommentarer'}/>
                    <Text name={'contact_name'} title={'Kontaktperson'}/>
                    <Text name={'contact_info'} title={'E-postaddress'}/>
                    <Submit title={'Skapa ny prenumeration'}/>
                </Form>
            }
        </div>;
    }
}

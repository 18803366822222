import React, {Component} from 'react';
import Utils from "../Utils";

export default class Select extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: Utils.GetHtmlID(),
        };
    }
    getReadOnly() {
        if (this.props.isReadOnly)
            return this.props.isReadOnly;
        return false;
    }
    getTextByValue(value) {
        let o = this.props.options.find(o => o.value === value);
        if (o)
            return o.text;
        return null;
    }
    render() {
        return <div>
            <label htmlFor={this.state.id}>{this.props.title}</label>
            {this.getReadOnly() ?
                <span>{this.getTextByValue(this.props.value)}</span>
                :
                <select id={this.state.id} value={this.props.value} onChange={e => {
                    this.props.onChange(e.target.value)
                }}>
                    <option>{this.props.notSelectedText}</option>
                    {this.props.options.map(o =>
                        <option key={o.value} value={o.value}>{o.text}</option>
                    )}
                </select>
            }
        </div>
    }
}

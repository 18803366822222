import React, {Component} from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default class CircularTimer extends Component {
	constructor(props) {
		super(props);

		this.countdownSeconds = 3*60;

		this.state = {
			startTime: null
		}
	}
	componentDidMount() {
		this.interval = setInterval(() => this.setState({ time: Date.now() }), 250);
	}
	componentWillUnmount() {
		clearInterval(this.interval);
	}
	onTimerClick() {
		if (this.state.startTime === null)
			this.setState({
				startTime: new Date().getTime()/1000
			});
		else
			this.setState({
				startTime: null
			});

	}
	render() {
		let timeLeft = this.countdownSeconds;
		
		if (this.state.startTime != null) {
			const now = new Date().getTime()/1000;
			timeLeft = this.countdownSeconds - (now - this.state.startTime);
		}

		if (timeLeft < -10) {
			// Autostop after 10 seconds
			this.setState({
				startTime: null
			});
		}

		let pathColor = `rgba(62, 199, 152, 1)`;
		let textStyle =  {
			// Text color
			fill: 'rgba(0, 156, 102, 1)',
			// Text size
			fontSize: '25px',
		};

		if (this.state.startTime !== null) {
			// Timer is active
			pathColor = `rgba(62, 152, 199, 1)`;
			textStyle.fill = 'rgba(0, 117, 177, 1)';

			// Flash red if less than 30 sec left
			if (timeLeft < 0.5*60 && Math.floor(timeLeft*4) % 2 === 0) {
				pathColor = `rgba(255, 0, 0, 1)`;
				textStyle.fill = '#ff0000';
			}
		}

		if (timeLeft < 0)
			timeLeft = 0;

		// Format seconds to string "2:45"
		let timeStr = new Date(timeLeft * 1000).toISOString().substr(15, 4);
		// CircularProgressbar wants a percent value
		let percentage = ((timeLeft / this.countdownSeconds)*100);

		if (this.state.startTime === null) {
			// Timer is stopped
			timeStr = '3 min';
			textStyle.fontSize = '16px';
		}

		return <div className={'CircularTimerContainer'} onClick={event => this.onTimerClick(event)}>
			<CircularProgressbar
				value={percentage}
				text={`${timeStr}`}
				styles={
					{
						...buildStyles({
							// Rotation of path and trail, in number of turns (0-1)
							rotation: 0,

							// Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
							strokeLinecap: 'butt',

							// Text size
							textSize: '16px',

							// How long animation takes to go from one percentage to another, in seconds
							pathTransitionDuration: 1,

							// Can specify path transition in more detail, or remove it entirely
							// pathTransition: 'none',

							// Colors
							pathColor: pathColor,
							trailColor: 'transparent',
							backgroundColor: 'transparent',
						}),
						text: textStyle,
					}
				}
			/>
		</div>;
	}
}

/**
 * This class contains static helper methods / tools
 */

export default class Tools {
	/**
	 * Returns true if any of the entries in the first array exist in the second array
	 *
	 * @param array1
	 * @param array2
	 * @returns {boolean}
	 */
	static arrayInArray(array1, array2) {
		for(let i=0; i<array1.length; i++) {
			if (array2.indexOf(array1[i]) !== -1)
				return true;
		}

		return false;
	}

	/**
	 * Converts a Google Maps WGS84 mapPosition object into an array of four pairs of decimal numbers
	 *
	 * @param mapPosition
	 * @returns {number[][]}
	 */
	static getBoundingBoxArray(mapPosition) {
		let ne = mapPosition.northEast;
		let sw = mapPosition.southWest;
		return [
			[sw.lat, sw.lng],
			[ne.lat, sw.lng],
			[ne.lat, ne.lng],
			[sw.lat, ne.lng],
		];
	}

	/**
	 * Returns true if a shallow compare of two arrays are equal
	 * Sutiable for comparing list of ID's. The order of the array entries doesn't matter
	 *
	 * @param arr1
	 * @param arr2
	 * @returns {boolean}
	 */
	static integerArrayEqual(arr1, arr2) {
		if (!Array.isArray(arr1))
			return false;

		if (!Array.isArray(arr2))
			return false;

		if (arr1.length !== arr2.length)
			return false;

		if (arr1.length === 0)
			return true;

		// Handle multidimensional arrays
		if (Array.isArray(arr1[0])) {
			for (let i=0; i<arr1.length; i++) {
				if (!Tools.integerArrayEqual(arr1[i], arr2[i]))
					return false;
			}

			return true;
		}

		// If we find an entry in the first array that doesnt exist in the second,
		// return false
		return arr1.find(entry => arr2.indexOf(entry) === -1) === undefined;
	}
}
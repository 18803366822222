import React, {Component} from 'react';
import Utils from "../Utils";
import subscriptionsStyles from "./Subscriptions.module.css";
import Message from "./Message";
import ReactTable from "react-table-6";
import SubscriptionDetails from "./SubscriptionDetails";

export default class Statistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            message: null,
            statisticsLoaded: false,
            statistics: {
                all_time: [],
                summary: []
            },
            uniqueDateList: [],
            uniqueSubscriptionIdList: [],
        }
    }
    load() {
        Utils.Fetch({
            action: 'load_statistics',
        }).then( res => {
            let statistics = {
                all_time: [],
                summary: []
            };
            if (Array.isArray(res.statistics.all_time))
                statistics.all_time = res.statistics.all_time;
            if (Array.isArray(res.statistics.summary))
                statistics.summary = res.statistics.summary;

            let uniqueDateList = [];
            let uniqueSubscriptionIdList = [];
            statistics.all_time.forEach(stat => {
                let foundDate = uniqueDateList.find(e => e === stat.create_date);
                if (!foundDate) uniqueDateList.push(stat.create_date);
                let foundId = uniqueSubscriptionIdList.find(e => e === stat.deck_subscription_id);
                if (!foundId) uniqueSubscriptionIdList.push(stat.deck_subscription_id);
            });

            this.setState({
                statistics: statistics,
                isError: false,
                message: null,
                uniqueDateList: uniqueDateList,
                uniqueSubscriptionIdList: uniqueSubscriptionIdList
            });
        }).catch(message => {
            this.setState({
                isError: true,
                message: message.toString(),
            });
        });

    }
    componentDidMount() {
        if (this.state.statisticsLoaded === false)
            this.load();
    }

    renderStatisticsSummary() {
        const columns_summary = [
            {
                Header: 'Händelse',
                accessor: 'event',
                Cell: props => <span>{SubscriptionDetails.getTranslatedEvent(props.value)}</span>
            }, {
                Header: 'Vad',
                accessor: 'event_target',
                Cell: props => <span>{SubscriptionDetails.getTranslatedEventTarget(props.value)}</span>
            }, {
                Header: 'Vad, typ',
                accessor: 'event_target_id',
                Cell: props => <span>{SubscriptionDetails.getTranslatedEventTargetId(this.props.baseData.categories, props.value)}</span>
            },
            {
                Header: 'Antal',
                accessor: 'count',
            }
        ];
        return <React.Fragment>
            <div className={subscriptionsStyles.TableTitle}>Total summering</div>
            <ReactTable
                columns={columns_summary}
                data={this.state.statistics.summary}
                //showPagination={false}
                showPageSizeOptions={false}
                defaultPageSize={18}
            />
        </React.Fragment>
    }
    renderStatistics() {
        let events = ['open','click_card_face','click_card_back','click_timer'];

        const columns_all_time = [
            {
                Header: 'Datum',
                accessor: 'create_date',
                defaultSortDesc: true,
                filterable: true,
                Filter: ({ filter, onChange }) =>
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}>
                        <option key={'all'} value={'all'}>Alla</option>
                        {this.state.uniqueDateList.map(d => <option key={d} value={d}>{d}</option>)}
                    </select>,
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    }
                    return String(row[filter.id]) === filter.value;
                },
            }, {
                Header: 'Händelse',
                accessor: 'event',
                Cell: props => <span>{SubscriptionDetails.getTranslatedEvent(props.value)}</span>,
                filterable: true,
                Filter: ({ filter, onChange }) =>
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}>
                        <option key={'all'} value={'all'}>Alla</option>
                        {events.map(e => <option key={e} value={e}>{SubscriptionDetails.getTranslatedEvent(e)}</option>)}
                    </select>,
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    }
                    return String(row[filter.id]) === filter.value;
                },
            }, {
                Header: 'Vad',
                accessor: 'event_target',
                Cell: props => <span>{SubscriptionDetails.getTranslatedEventTarget(props.value)}</span>,
            },
            {
                Header: 'Antal',
                accessor: 'count',
            }, {
                Header: 'Pren. ID',
                accessor: 'deck_subscription_id',
                filterable: true,
                Filter: ({ filter, onChange }) =>
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}>
                        <option key={'all'} value={'all'}>Alla</option>
                        {this.state.uniqueSubscriptionIdList.map(e => <option key={e} value={e}>{e}</option>)}
                    </select>,
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    }
                    return String(row[filter.id]) === filter.value;
                },
            }
        ];
        return <React.Fragment>
            <div className={subscriptionsStyles.TableTitle}>Statistik, summerat per dag, händelse och prenumeration</div>
            <ReactTable
                columns={columns_all_time}
                data={this.state.statistics.all_time}
                defaultPageSize={10}
                minRows={0}
                showPageSizeOptions={false}
                style={{marginBottom: '1rem'}}
                defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
            />
        </React.Fragment>;
    }

    render() {
        return <React.Fragment>
            {this.state.message ? <Message isError={this.state.isError}>{this.state.message}</Message> : null}
            {this.renderStatisticsSummary()}
            <div className={subscriptionsStyles.TableTitle}>&nbsp;</div>
            {this.renderStatistics()}
        </React.Fragment>
    }
}

import React, {Component} from 'react';
import InfoPage from "./InfoPage";
import Menu from "./Menu";

export default class Welcome extends Component {
    render() {
        return <InfoPage>
            <Menu>
                <div>Text i menyn välkomstsidan</div>
            </Menu>
            <h2>Välkommen till Talarkortleken</h2>
            <p>Talarkortleken är ett kul och kreativt sätt för team att öva upp sin förmåga att hålla tal. Kontakta oss på Ordrum för en demonstration.</p>
            <h3>Kontakt</h3>
            <p>
                Rosengatan 2A<br/>
                413 10 Göteborg
            </p>

            <p>
                031 743 34 40<br/>
                post@ordrum.se<br/>
            </p>
        </InfoPage>
    }
}
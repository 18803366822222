import React, {Component} from 'react';
import logo from "../../logo.svg";
import {getTranslation} from "../language/Language";
import Utils from "../Utils";

export default class Page extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: null,
            link:null
        }
    }

    componentDidMount() {
        if (Utils.language) {
            let title = getTranslation('page_title', Utils.language);
            const link = getTranslation('ordrum_link', Utils.language);
            if (this.props.deck)
                title += ' – ' + this.props.deck.name.toLowerCase();
            this.setState({title,link});
        }
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <a href={this.state.link}><img src={logo} className="App-logo" alt="logo"/></a>
                    <div className="App-name">{this.state.title}</div>
                </header>
                <div className="App-body">
                    {this.props.children}
                </div>
            </div>
        );
    }
}
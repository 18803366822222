import React, {Component} from 'react';
import adminStyles from "./Admin.module.css";

export default class Message extends Component {
    render() {
        let className = this.props.isError ? adminStyles.ErrorMessage : adminStyles.InfoMessage;
        return <div className={className}>
            {this.props.children}
        </div>
    }
}
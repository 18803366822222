import React, {Component} from "react";
import logo from "../../logo.svg";
import Utils from "../Utils";
import {getTranslation} from "../language/Language";

const CARD_ROTATION_DEGREES = 5;
const CARD_X_MOVE_ENTROPY = 5;
const CARD_Y_MOVE_ENTROPY = 5;

export default class Card extends Component {
	constructor(props) {
		super(props);

		this.state = {
			left: Math.round(Math.random()*CARD_X_MOVE_ENTROPY) * (Math.round(Math.random()) * -2 + 1),
			top: Math.round(Math.random()*CARD_Y_MOVE_ENTROPY) * (Math.round(Math.random()) * -2 + 1),
			rotation: Math.random() * CARD_ROTATION_DEGREES * (Math.round(Math.random()) * -2 + 1),


			topCardIndex: 0,
			isFlipped: false,

			flipClass: 'is-flipped-ltr'//(Math.round(Math.random()) === 0) ? 'is-flipped-ltr' : 'is-flipped-rtl'
		};
		this.toggleFlip = this.toggleFlip.bind(this);
	}
	render() {
		//let letter = this.props.backText[0];
		let style = this.props.isTopCard ? null : {transform: "rotate("+this.state.rotation+"deg)" };

		let onClick = this.props.isTopCard ? this.toggleFlip : () => {};

		let cardWrapperClassName;
		if (this.state.isFlipped === false) {
			cardWrapperClassName = 'CardWrapper';
		}
		if (this.state.isFlipped === 'partly') {
			cardWrapperClassName =  'CardWrapper is-flipped-hax';
		}
		if (this.state.isFlipped === true) {
			cardWrapperClassName =  'CardWrapper ' + this.state.flipClass;
		}

		cardWrapperClassName = cardWrapperClassName + ' category-' + this.props.category;

		let frontText;
		if (this.props.isTopCard) {
			frontText = this.props.cards[this.state.topCardIndex].text;
		}

		return (
			<div className="scene" style={style} onClick={onClick}>

				<div className={cardWrapperClassName} style={{left: this.state.left + 'px', top: this.state.top + 'px'}}>
					<div className={"Card Card--back"}>
						<div className="Text">{this.props.backText}</div>
					</div>
					<div className="Card Card--front">
						<img src={logo} className="Card-logo" alt="logo" />
						<div className="Text" dangerouslySetInnerHTML={{__html:frontText}}/>
						<div className="Url">{getTranslation('talarkortleken_url',Utils.language)}</div>
					</div>
				</div>

			</div>
		);
	}



	toggleFlip(evt) {

		if (this.state.isFlipped === false) this.props.saveStatisticsCallback('click_card_face', 'category', this.props.category);
		if (this.state.isFlipped === true) this.props.saveStatisticsCallback('click_card_back', 'category', this.props.category);

		// Did the use click on the right or left side of the card?
		//let bb = evt.target.getBoundingClientRect();
		//let relativeX = evt.clientX - bb.x;

		//let flipClass = (relativeX >= (bb.width/2))  ? 'is-flipped-ltr' : 'is-flipped-rtl';

		let topCardIndex = this.state.topCardIndex;
		if (this.state.isFlipped === false) {
			//topCardIndex = (this.state.topCardIndex + 1) % this.props.cards.length;
			topCardIndex = (topCardIndex + 1) % this.props.cards.length;
		}

		if (this.state.isFlipped === false) {
			this.setState({
				isFlipped: 'partly',
				topCardIndex: topCardIndex,
			});
			this.forceUpdate(() =>
				setTimeout(() => this.setState({
					isFlipped: true
				}), 400)
			);
		} else {
			this.setState({
				isFlipped: false
			});
		}

	}
}
var idCount = 0;

export default class Utils {

    static language = 'swedish';

    static getFetchBase() {
        // noinspection JSUnresolvedVariable
        let url = '/'; // process.env.REACT_APP_BACKEND_SERVER_ADDRESS;
        // noinspection JSUnresolvedVariable
        if (process.env.REACT_APP_BACKEND_DIRECTORY !== '/')
            url += process.env.REACT_APP_BACKEND_DIRECTORY;

        return url;
    }
    static getFetchGetUrl() {
        let url = Utils.getFetchBase();
        // noinspection JSUnresolvedVariable
        url += process.env.REACT_APP_BACKEND_SCRIPT_NAME;

        return url;
    }
    static Fetch(postData) {
        return new Promise((resolve, reject) => {
            fetch(Utils.getFetchGetUrl(), {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, cors, *same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, same-origin, *omit
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                redirect: "follow", // manual, *follow, error
                referrer: "no-referrer", // no-referrer, *client
                body: JSON.stringify(postData), // body data type must match "Content-Type" header
            })
            .then(response => response.json()) // parses response to JSON
            .then(responseJSON => {
                resolve(responseJSON);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });

        });

    }
    static DateTimeToDate(sDate) {
        if (!sDate)
            return "Okänd";

        let month = [
            'jan','feb','mars','apr','maj','juni','juli','aug','sep','okt','nov','dec'
        ];

        let year = sDate.substring(0,4);
        let monthIndex = sDate.substring(5, 7);
        monthIndex = parseInt(monthIndex, 10) - 1;
        let monthName = month[ monthIndex ];
        let dayNumber = parseInt(sDate.substring(8,10));

        return '' + year + ' ' + dayNumber + ' ' + monthName;
    }

    static DateTimeToTime(sDate) {
        if (!sDate)
            return "??.??";
        return sDate.substring(11,16).replace(':', '.');
    }

    static StringToDateTime(sDate) {
        return sDate.substring(0,16);
    }

    static StringToDate(sDate) {
        return sDate.substring(0,10);
    }
    static GetHtmlID() {
        return 'id_' + idCount++;
    }
}
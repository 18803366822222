import React, {Component} from 'react';
import Utils from "../Utils";

export default class TextArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: Utils.GetHtmlID(),
        };
    }
    getReadOnly() {
        if (this.props.isReadOnly)
            return this.props.isReadOnly;
        return false;
    }
    render() {
        return <div>
            <label htmlFor={this.state.id}>{this.props.title}</label>
            {this.getReadOnly() ?
                <span>{this.props.value}</span>
                :
                <textarea id={this.state.id}
                          value={this.props.value}
                          onChange={e => this.props.onChange(e.target.value)}/>
            }
        </div>
    }
}
import React, {Component} from 'react';
import Card from "./Card";


export default class Deck extends Component {
	constructor(props) {
		super(props);

		this.state = {
			topCardIndex: 0,
			topCardFlipped: false,
			topCardFlipClass: (Math.round(Math.random()) === 0) ? 'is-flipped-ltr' : 'is-flipped-rtl'
		};
		this.onCardClick = this.onCardClick.bind(this);
	}
	onCardClick(flipClass) {
/*
		if (!this.state.topCardFlipped) {
			console.log('Top card is not flipped. Will generate a new text, then flip the card');
			this.setState({
					topCardIndex: (this.state.topCardIndex + 1) % this.props.cards.length,
				},
				() => {
					this.setState({
						topCardFlipped: !this.state.topCardFlipped,
						topCardFlippedClass: flipClass
					})
				});
		} else {
			console.log('Top card is flipped (text side up). Will flip card');
			this.setState({
				topCardFlipped: !this.state.topCardFlipped,
				topCardFlippedClass: flipClass
			});
		}

 */
	}
	render() {
		let {category_id, category_text, cards} = this.props;

		let deck = [];
		for (let i=0; i<6; i++)
			deck.push(<Card key={i+1} category={category_id} backText={category_text} frontText=""/>);

		deck.push(<Card key={0} category={category_id} isFlipped={this.state.topCardFlipped} flipClass={this.state.topCardFlipClass} backText={category_text} cards={cards} isTopCard={true} saveStatisticsCallback={this.props.saveStatisticsCallback} onCardClick={this.onCardClick}/>);

		return <div className="Deck">{deck}</div>
	}
}
import React, {Component} from 'react';
import Text from "./Text";

export default class Date extends Component {
    getValue() {
        if (this.props.value && this.props.value.length > 10)
            return this.props.value.substring(0,10);
        return this.props.value;
    }
    render() {
        return <Text type={'date'}
                     title={this.props.title}
                     value={this.getValue()}
                     isReadOnly={this.props.isReadOnly}
                     onChange={value => this.props.onChange(value)}/>
    }
}

import React, {Component} from 'react';
import Utils from "../Utils";
import Login from "./Login";
import styles from "./Admin.module.css";
import Menu from "./Menu";
import Subscriptions from "./Subscriptions";
import CreateSubscription from "./CreateSubscription";
import Statistics from "./Statistics";
import Message from "./Message";

export default class Admin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            adminBaseData: null,
            page: null,
            message: false,
            isError: false,
        }
    }
    static getPageList() {
        return [
            {title: 'Ny prenumeration', id: 'create_subscription', component: CreateSubscription},
            {title: 'Prenumerationer', id: 'subscriptions', component: Subscriptions},
            {title: 'Statistik', id: 'statistics', component: Statistics},
        ];
    }

    onLogin(username, password) {
        Utils.Fetch({
            action:'login',
            username: username,
            password: password,
        }).then( res => {
            if (!res.is_authenticated) this.setState({
                isAuthenticated: res.is_authenticated,
                adminBaseData: res.admin_base_data,
                isError: true,
                message: 'Kunde inte logga in',
            });
            else this.setState({
                isAuthenticated: res.is_authenticated,
                adminBaseData: res.admin_base_data,
                isError: false,
                message: '',
            });
        }).catch(message => {
            this.setState({
                isError: true,
                message: message.toString(),
            });
        });
    }
    renderLogin() {
        return <div className={styles.Admin}><Login onLogin={(username, password) => this.onLogin(username, password)}/></div>
    }
    renderPage() {
        if (!this.state.isAuthenticated)
            return this.renderLogin();

        if (this.state.page) {
            let Component = this.state.page.component;
            return <Component baseData={this.state.adminBaseData} callback={this.state.page.callback}/>;
        }

        return <div>
            Välj sida i menyn för att fortsätta
        </div>
    }
    render() {

        return <div className={styles.Admin}>
            <Menu items={Admin.getPageList()} onClick={(event, item) => this.setState({page: item})}/>
            <div className={styles.PageContainer}>
                <div className={styles.PageTitle}>
                    {this.state.page ? this.state.page.title : 'Admin'}
                </div>

                {this.state.message ? <Message isError={this.state.isError}>
                    {this.state.message}
                </Message> : null}

                <div className={styles.PageContents}>
                {this.renderPage()}
                </div>
            </div>
        </div>

    }
}

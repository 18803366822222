import React, {Component} from 'react';
import Page from "./Page";
import styles from "./Page.module.css";

export default class InfoPage extends Component {
    render() {
        return <Page>
            <div className={styles.Info}>
                {this.props.children}
            </div>
        </Page>
    }
}
import {swedish} from "./swedish";
import {english} from "./english";


export const getTranslation = (key, language = 'swedish', ...argv) => {
    const translationList = {
        swedish,
        english,
    };
    if (!translationList[language])
        throw new Error('unknown language');
    if (!translationList[language][key])
        throw new Error('unknown language key');
    return formatText(translationList[language][key], ...argv);
}

const formatText = (string, ...argv) => {
    if (!argv.length)
        return string;
    else
        return formatText(string.replace("$", argv.shift()), ...argv);
}

export const getLanguage = () => {
    return navigator.language in ['swedish','english'] ? navigator.language : 'english';
}
import React, {Component} from 'react';
import styles from "./Menu.module.css";
import InfoToolbar from "./InfoToolbar";
import {getTranslation} from "../language/Language";

export default class Menu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isExpanded: false
		};
		this.containerRef = React.createRef();
	}

	getHamburgerClass() {
		let hamburgerClass = styles.hamburgerContainer;
		if (this.state.isExpanded)
			hamburgerClass += ' ' + styles.expanded;

		hamburgerClass += ' ' + styles.hamburgerDesktop;

		return hamburgerClass;
	}

	getContentContainerClassName() {
		let expandedClass = this.state.isExpanded ? styles.expanded : null;

		let contentClassName = styles.contents + ' ' + styles.desktop;
		if (expandedClass)
			contentClassName += ' ' + expandedClass;

		return contentClassName;
	}

	handleClickOutside = (event) => {
		if (!this.state.isExpanded)
			return;

		if (!this.containerRef || !this.containerRef.current)
			return;

		if (!this.containerRef.current.contains(event.target)) {
			let newIsExpanded = false;
			this.setState({isExpanded: newIsExpanded});
			if (this.props.onClick)
				this.props.onClick(newIsExpanded);

			event.preventDefault();
			event.stopPropagation();
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.isExpanded === this.state.isExpanded)
			return;

		if (this.state.isExpanded) {
			window.addEventListener('mousedown', this.handleClickOutside, true);
			window.addEventListener('touchstart', this.handleClickOutside, true);
		} else {
			window.removeEventListener('mousedown', this.handleClickOutside, true);
			window.removeEventListener('touchstart', this.handleClickOutside, true);
		}
	}

	onClick() {
		let newIsExpanded = !this.state.isExpanded;
		this.setState({isExpanded: newIsExpanded});
		if (this.props.onClick)
			this.props.onClick(newIsExpanded);
	}

	render() {

		let contentClassName = this.getContentContainerClassName();

		let hamburgerClass = this.getHamburgerClass();

		return (
			<div ref={this.containerRef}>
				<InfoToolbar deck={this.props.deck} observer_url={this.props.observer_url}/>
				<div className={hamburgerClass} onClick={() => this.onClick()}>
					<div className={styles.bar1}/>
					<div className={styles.bar2}/>
					<div className={styles.bar3}/>
				</div>
				<div className={contentClassName}>
					{getTranslation('about_menu', this.props?.deck?.language)}
				</div>
			</div>
		);
	}
}

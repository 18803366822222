import React, {Component} from 'react';
import Text from "./Text";

export default class Password extends Component {
    render() {
        return <Text type={'password'}
                     title={this.props.title}
                     value={this.props.value}
                     isReadOnly={this.props.isReadOnly}
                     onChange={value => this.props.onChange(value)}/>
    }
}

import React, {Component} from 'react';
import styles from "./Subscriptions.module.css";
import Utils from "../Utils";
import SubscriptionDetails from "./SubscriptionDetails";
import Button from "./Button";

export default class Subscriptions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            subscriptions: [],
            selectedSubscriptionID: null,
        };
    }

    load() {
        Utils.Fetch({action:'load_subscriptions'})
            .then( responseJSON => {
                this.setState({
                    subscriptions: responseJSON.subscriptions,
                })
            });
    }

    componentDidMount() {
        this.load();
    }

    renderSubscriptionDetails() {
        if (this.state.selectedSubscriptionID === null)
            return null;

        return <SubscriptionDetails
            baseData={this.props.baseData}
            ID={this.state.selectedSubscriptionID}
            onClose={() => {
                this.setState({
                    selectedSubscriptionID: null
                });
                this.load();
            }}
        />;
    }

    render() {
        return <div>
            {this.renderSubscriptionDetails()}
            <div className={styles.TableTitle}>Prenumerationer</div>
            <table className={styles.blueTable}>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Skapad</th>
                    <th>Giltig till</th>
                    <th>Kontakt, namn</th>
                    <th>Kontakt, uppgifter</th>
                    <th>Kommentar</th>
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {this.state.subscriptions.map(s => {
                        return <tr key={s.id}>
                            <td>{s.id}</td>
                            <td>{Utils.StringToDate(s.create_date)}</td>
                            <td>{Utils.StringToDate(s.expire_date)}</td>
                            <td>{s.contact_name}</td>
                            <td>{s.contact_info}</td>
                            <td>{s.comment}</td>
                            <td><input type="button" value={"Detaljer"} onClick={() => this.setState({selectedSubscriptionID: s.id})}/></td>
                        </tr>
                    }
                )}
                </tbody>
            </table>
            <Button title={'Uppdatera'} onClick={() => this.load()}/>
        </div>
    }
}